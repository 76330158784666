/**
 * Frame hook (top menu, footer, settings, and other things that are not page-specific).
 * Will load initial data provided by context on initial load, and fetch new values when culture or market changes
 * Always try to provide a value, even if it's from the previously loaded frame, but the isLoading will indicate
 * if it's serving stale data
 */

import { useContext, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetcher } from '~/shared/utils/request/utils/fetcher';
import { FrameContext } from '../context/FrameProvider';
import { Frame } from '..';
import { useMarket } from '~/shared/utils/market';

export const useFrame = () => {
    const { initialData } = useContext(FrameContext);
    const { culture, market } = useMarket();

    const { data: frameData, isLoading } = useQuery<Frame | null, Error>({
        queryKey: ['/api/proxy/content/frame', culture, market],
        queryFn: fetcher<Frame | null>('/api/proxy/content/frame', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ url: '/', culture, market }),
        }),
        initialData,
    });

    /**
     * While this action is inexpensive, this will have different references
     * on each re-render consequently causing re-renders on subscribed components.
     * We will memoize it to keep the reference consistent until necessary.
     */
    const data: Frame = useMemo(
        () => ({
            market: {},
            ...initialData,
            ...frameData,
        }),
        [initialData, frameData],
    );

    return {
        data,
        isLoading,
    };
};
